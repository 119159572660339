

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slider1{
  background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), url("../../public/slider3.jpg");
}


.slider2{
  background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), url("../../public/slider1.jpg");
}


.slider3{
  background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)), url("../../public/slider2.jpg");
}
.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background-position: center !important; 
  background-repeat: no-repeat !important;
  background-attachment: scroll !important;
  background-size: cover !important;
}


.service-background{
  /* width: 600px; */
  height: 400px;
  /* padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem); */
  /* background-position: center !important;  */
  /* background-repeat: no-repeat !important; */
  /* background-attachment: scroll !important; */
  background-size: cover !important;
  background: url("../../public/frame.png");
}



.circle-background{
  margin-left: 10px;
  width: 180px;
  height: 180px;
  padding-top: 2.5rem;
  /* padding-bottom:10rem; */
  background-position: center !important; 
  background-repeat: no-repeat !important;
  background-attachment: scroll !important;
  background-size: cover !important;
  background: url("../../public/circle.png");
  font-size: 60px;
  font-weight: bold;
}




.slick-dots{
  bottom: 5%;
}

.slick-prev{
  display: none;
}

.slick-next{
  display: none;
}


.slick-dots li button:before{
  font-size:12px
}

.buttonSlider{
  padding: 5px 60px !important;
  border-radius: 25px !important;
}
.app-bar{
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.0),0px 1px 10px 0px rgba(0,0,0,0) !important;
}

.bold-divider{
  margin-top: 50px !important;
  border-width: 3px !important;
  border-color:#B3D5E7 !important;
}

.text-half-white{
  /* color:rgba(255, 255, 255, 0.5) !important; */
}

.top-50{
  font-size: 20px;
  margin-top: 50px;
}

h1 {
  font-size: 7rem;
}

h3 {
  font-size: 3.5rem;
}

.block-divider{
  width: 100%;
  margin-left: 5px;
}
@media only screen and (max-width: 768px) {
  h1 {
      font-size: 4rem;
  }

  h3 {
    font-size: 2rem;
  }
  
  .cookieButtons{
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  h1 {
      font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }
}

.footer-link{
margin: 0;
    font-family: "Outfit", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.334;
    text-decoration: none;
    color: #35474E;
}

.CookieConsent{
  
    font-family: "Outfit", Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}
