






@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

html{
  background-color: #FEF7F0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

#root{
  background-color: #FEF7F0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.emailLogin input,.passwordLogin input{
  /* -webkit-box-shadow: 0 0 0 100px #151616 inset !important; */
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  background-color: #fef7f0 !important;
}
/* MUI */
.admin .MuiPaper-root.MuiDrawer-paper{
  background-color: #0f0f0f !important  
}
.admin .MuiPaper-root  {
    color:#fff !important;
    background-color: #FEF7F0 !important;
  }

  .admin .MuiPaper-root h6  {
    color:#000 !important;

  }


  .admin .MuiSvgIcon-root{
    color: #fff;
  }

  .MuiFormLabel-root,
  .MuiInputBase-root,
  .admin .MuiTableCell-head,
  .admin .MuiTableCell-root,
  .admin .MuiIconButton-root,
  .admin .MuiTypography-root,
  .admin .MuiSvgIcon-root,
  .MuiCheckbox-root{
    /* color: #fff !important; */
  }

  
   .MuiInputBase-input,.admin .MuiButtonBase-root{
    /* background: #6c6c6c2e !important; */
}

.admin .MuiButtonBase-root.MuiIconButton-root{
  background: #0000000f !important;
}

.admin .MuiChip-outlined{
  border: 1px solid black !important;
}
.admin .MuiChip-root{
  color: #000 !important;
}

.scroll {
  /* height: 300px; */
  /* width: 50%; */
  overflow: auto;
  padding: 0 10px;
}
 .scroll::-webkit-scrollbar, html::-webkit-scrollbar,.MuiPaper-root::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track,html::-webkit-scrollbar-track,.MuiPaper-root::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.scroll::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb,.MuiPaper-root::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #363636;
}

.admin .list-icons svg{
  width: 48px !important;
  height: 48px !important;
}

.admin .list-icons-menu svg{
  width: 24px !important;
  height: 24px !important;
}

.admin .MuiSelect-select.MuiSelect-select{
padding-right: 24px !important;
}
.admin .MuiOutlinedInput-input{
  padding: 18.5px 14px !important;
}


.slick-prev:before, .slick-next:before {

  color: #35474E !important;

}